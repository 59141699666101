* {
  font-size: 14px;
  font-family: 'Roboto';
}

body {
  background-color: #062d700d;
  margin: 0;
}

a {
  text-decoration: none !important;
}

th > span {
  font-size: 12px;
}

td {
  font-size: 14px !important;
}

.MuiTooltip-tooltip {
  font-size: 12px !important;
}

.MuiButton-root {
  font-weight: bold !important;
  text-transform: none !important;
}

.MuiTab-root {
  text-transform: none !important;
  font-size: 18px !important;
  margin-top: -6px !important;
}

.MuiTableCell-head {
  font-weight: bold !important;
}

.MuiTableContainer-root {
  height: 100%;
}

.MuiDialogActions-root {
  padding: 0 24px 16px !important;
}

.MuiInputBase-root > fieldset > legend > span {
  font-size: 11px;
}

li {
  min-height: unset !important;
}

::selection {
  background-color: #1d57aa;
  color: white;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(32, 33, 36, 0.08);
}

::-webkit-scrollbar-thumb {
  background: rgba(32, 33, 36, 0.08);
  border-radius: 10px;
  border: solid 2px rgba(32, 33, 36, 0.08);
}

:hover::-webkit-scrollbar-thumb {
  background: rgba(25, 118, 210, 0.3);
  border: solid 2px rgba(25, 118, 210, 0.3);
}

::-webkit-scrollbar-thumb:hover {
  background: #1d57aa;
  border: solid 2px #1d57aa;
}
